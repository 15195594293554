<template>
    <zw-sidebar @shown="shown" :title="$t('common.title.json')">
        <json-viewer v-if="payload.data" :value="payload.data"></json-viewer>
    </zw-sidebar>
</template>

<script>
export default {
    name: 'JsonModal',
    data() {
        return {
            payload: {},
        }
    },
    methods: {
        shown() {
        }
    },
}
</script>